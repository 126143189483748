import './styles.css'

function Divider() {
    return(
        <section className='Divider-container'>
            <hr/>
        </section>
    )
}

export default Divider