const WalletSetup={
    Rinkby:{
        apiKey:'2458f30d-25a5-40f7-b4f1-73a50c1651dd',
        networkId:4
    },
    Goerli:{
        apiKey:'2458f30d-25a5-40f7-b4f1-73a50c1651dd',
        networkId:5
    },

}

export default WalletSetup